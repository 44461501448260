<template>
	<div class="report_month report_content">
		<div class="filter">
			<div class="search">
				<template v-if="show_list">
					<el-date-picker
						size="small"
						v-model="list_year"
						type="year"
						style="width:118px;"
						:clearable="false"
						value-format="yyyy"
						@change="getAllList(), getList()"
					/>

					<div class="area">
						<span>填报单位：</span>
						<el-select
							size="small"
							v-model="list_area"
							popper-class="global_select_popper"
							style="width:118px;"
							:disabled="qu_project_power.area > 0"
							@change="getAllList(), getList()"
						>
							<el-option
								v-for="item in areaList"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							></el-option>
						</el-select>
					</div>
				</template>

				<template v-else>
					<div class="back" @click="(show_list = true), getAllList()">
						<i class="el-icon-arrow-left"></i>
						返回
					</div>
					<el-date-picker
						size="small"
						v-model="month"
						type="month"
						placeholder="选择月度"
						value-format="yyyy-MM"
						:clearable="false"
						style="width:118px;"
						@change="getList"
					></el-date-picker>
					<div
						class="area"
						v-if="!qu_project_power.area || qu_project_power.area === -1"
					>
						<span>填报单位：</span>
						<el-select
							size="small"
							v-model="area"
							popper-class="global_select_popper"
							@change="
								initMonthForm();
								getList();
							"
							style="width:118px;"
						>
							<el-option
								v-for="item in areaList"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							></el-option>
						</el-select>
					</div>
				</template>
			</div>
			<el-button
				size="small"
				type="primary"
				@click="exportForm"
				class="button_98px"
				v-if="!show_list && area"
				>导出表格
			</el-button>
		</div>
		<div class="content">
			<div class="report_table" v-if="show_list">
				<span class="label">
					{{ list_year }}年{{
						areaList.find((it) => it.value == list_area).label
					}}项目进度月度报表
				</span>
				<div class="table">
					<el-table :data="list_data" height="100%" style="width: 100%" border>
						<el-table-column label="月份" min-width="68" align="center">
							<template slot-scope="scope">
								{{ scope.row.month }}月
							</template>
						</el-table-column>
						<el-table-column label="地区" min-width="68" align="center">
							<template slot-scope="scope">
								<div>{{ scope.row.xiang }}</div>
							</template>
						</el-table-column>
						<el-table-column label="是否填报" min-width="68" align="center">
							<template slot-scope="scope">
								<span :style="{ color: scope.row.id ? '#06BA3D' : 'red' }">{{
									scope.row.id ? "是" : "否"
								}}</span>
							</template>
						</el-table-column>
						<el-table-column label="操作" min-width="68" align="center">
							<template slot-scope="scope">
								<span class="button" @click="editForm(scope.row)">去填报</span>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>

			<template v-else>
				<h3>{{ initArea(area) }}对口帮扶资金项目进度情况月度报表</h3>
				<div class="table_info">
					<div>填报单位：{{ initArea(area) }}</div>
					<div>
						填报时间：{{ new Date(month).getFullYear() }}年{{
							new Date(month).getMonth() + 1
						}}月
					</div>
				</div>
				<div class="report_table">
					<div class="table">
						<el-table :data="tableData" style="width: 100%;" height="100%">
							<el-table-column
								type="index"
								label="序号"
								width="60"
								align="center"
							></el-table-column>
							<el-table-column label="州市" width="86" align="center">
								<template slot-scope="scope">
									<div v-if="!scope.row.edit">{{ scope.row.shi }}</div>
									<el-input
										v-else
										size="mini"
										v-model="monthForm.shi"
										placeholder="州名"
									></el-input>
								</template>
							</el-table-column>
							<el-table-column label="县" width="86" align="center">
								<template slot-scope="scope">
									<div v-if="!scope.row.edit">{{ scope.row.xiang }}</div>
									<el-input
										v-else
										size="mini"
										v-model="monthForm.xiang"
										placeholder="县名"
									></el-input>
								</template>
							</el-table-column>
							<el-table-column label="年度帮扶项目推进实施情况" align="center">
								<el-table-column
									:label="'计划项目数\n（个）'"
									min-width="140"
									align="center"
								>
									<template slot-scope="scope">
										<div v-if="!scope.row.edit">
											<span
												v-if="
													scope.row.plan_num !== '' &&
														scope.row.plan_num !== null &&
														scope.row.plan_num !== undefined
												"
												>{{ scope.row.plan_num }}个</span
											>
										</div>
										<el-input-number
											v-else
											size="mini"
											v-model="monthForm.plan_num"
											:precision="0"
											:step="1"
											:controls="false"
											placeholder="填写数量"
										></el-input-number>
									</template>
								</el-table-column>
								<el-table-column
									:label="'当月开工数\n（个）'"
									min-width="140"
									align="center"
								>
									<template slot-scope="scope">
										<div v-if="!scope.row.edit">
											<span
												v-if="
													scope.row.month_start_num !== '' &&
														scope.row.month_start_num !== null &&
														scope.row.month_start_num !== undefined
												"
												>{{ scope.row.month_start_num }}个</span
											>
										</div>
										<el-input-number
											v-else
											size="mini"
											v-model="monthForm.month_start_num"
											:precision="0"
											:step="1"
											:controls="false"
											placeholder="填写数量"
										></el-input-number>
									</template>
								</el-table-column>
								<el-table-column
									:label="'累计开工数\n（个）'"
									min-width="140"
									align="center"
								>
									<template slot-scope="scope">
										<div v-if="!scope.row.edit">
											<span
												v-if="
													scope.row.start_num !== '' &&
														scope.row.start_num !== null &&
														scope.row.start_num !== undefined
												"
												>{{ scope.row.start_num }}个</span
											>
										</div>
										<el-input-number
											v-else
											size="mini"
											v-model="monthForm.start_num"
											:precision="0"
											:step="1"
											:controls="false"
											placeholder="填写数量"
										></el-input-number>
									</template>
								</el-table-column>
								<el-table-column
									:label="'当月完工数\n（个）'"
									min-width="140"
									align="center"
								>
									<template slot-scope="scope">
										<div v-if="!scope.row.edit">
											<span
												v-if="
													scope.row.month_finish_num !== '' &&
														scope.row.month_finish_num !== null &&
														scope.row.month_finish_num !== undefined
												"
												>{{ scope.row.month_finish_num }}个</span
											>
										</div>
										<el-input-number
											v-else
											size="mini"
											v-model="monthForm.month_finish_num"
											:precision="0"
											:step="1"
											:controls="false"
											placeholder="填写数量"
										></el-input-number>
									</template>
								</el-table-column>
								<el-table-column
									:label="'累计完工数\n（个）'"
									min-width="140"
									align="center"
								>
									<template slot-scope="scope">
										<div v-if="!scope.row.edit">
											<span
												v-if="
													scope.row.finish_num !== '' &&
														scope.row.finish_num !== null &&
														scope.row.finish_num !== undefined
												"
												>{{ scope.row.finish_num }}个</span
											>
										</div>
										<el-input-number
											v-else
											size="mini"
											v-model="monthForm.finish_num"
											:precision="0"
											:step="1"
											:controls="false"
											placeholder="填写数量"
										></el-input-number>
									</template>
								</el-table-column>
								<el-table-column
									:label="'项目进度\n（%）'"
									min-width="140"
									align="center"
								>
									<template slot-scope="scope">
										<div v-if="!scope.row.edit">
											<span
												v-if="
													scope.row.project_speed_rate !== '' &&
														scope.row.project_speed_rate !== null &&
														scope.row.project_speed_rate !== undefined
												"
												>{{ scope.row.project_speed_rate }}%</span
											>
										</div>
										<el-input-number
											v-else
											size="mini"
											v-model="monthForm.project_speed_rate"
											:precision="0"
											:step="1"
											:controls="false"
											placeholder="填写进度"
										></el-input-number>
									</template>
								</el-table-column>
							</el-table-column>
							<el-table-column label="年度帮扶资金拨付使用情况" align="center">
								<el-table-column
									:label="'计划资金总额\n（万元）'"
									min-width="140"
									align="center"
								>
									<template slot-scope="scope">
										<div v-if="!scope.row.edit">
											<span
												v-if="
													scope.row.plan_money !== '' &&
														scope.row.plan_money !== null &&
														scope.row.plan_money !== undefined
												"
												>{{ scope.row.plan_money }}万元</span
											>
										</div>
										<el-input-number
											v-else
											size="mini"
											v-model="monthForm.plan_money"
											:step="1"
											:controls="false"
											placeholder="填写资金"
										></el-input-number>
									</template>
								</el-table-column>
								<el-table-column
									:label="'到位资金\n（万元）'"
									min-width="140"
									align="center"
								>
									<template slot-scope="scope">
										<div v-if="!scope.row.edit">
											<span
												v-if="
													scope.row.daowei_money !== '' &&
														scope.row.daowei_money !== null &&
														scope.row.daowei_money !== undefined
												"
												>{{ scope.row.daowei_money }}万元</span
											>
										</div>
										<el-input-number
											v-else
											size="mini"
											v-model="monthForm.daowei_money"
											:step="1"
											:controls="false"
											placeholder="填写资金"
										></el-input-number>
									</template>
								</el-table-column>
								<el-table-column
									:label="'当月报账资金\n（万元）'"
									min-width="140"
									align="center"
								>
									<template slot-scope="scope">
										<div v-if="!scope.row.edit">
											<span
												v-if="
													scope.row.month_money !== '' &&
														scope.row.month_money !== null &&
														scope.row.month_money !== undefined
												"
												>{{ scope.row.month_money }}万元</span
											>
										</div>
										<el-input-number
											v-else
											size="mini"
											v-model="monthForm.month_money"
											:step="1"
											:controls="false"
											placeholder="填写资金"
										></el-input-number>
									</template>
								</el-table-column>
								<el-table-column
									:label="'累计报账资金\n（万元）'"
									min-width="140"
									align="center"
								>
									<template slot-scope="scope">
										<div v-if="!scope.row.edit">
											<span
												v-if="
													scope.row.money !== '' &&
														scope.row.money !== null &&
														scope.row.money !== undefined
												"
												>{{ scope.row.money }}万元</span
											>
										</div>
										<el-input-number
											v-else
											size="mini"
											v-model="monthForm.money"
											:step="1"
											:controls="false"
											placeholder="填写资金"
										></el-input-number>
									</template>
								</el-table-column>
							</el-table-column>
							<el-table-column
								label="操作"
								align="center"
								width="160"
								fixed="right"
								v-if="qu_project_power.power.edit"
							>
								<template slot-scope="scope">
									<div v-if="scope.row.id">
										<el-button
											size="mini"
											type="primary"
											class="button_58px"
											v-if="!monthForm.id"
											:disabled="loading"
											@click="editRow(scope.row)"
											>编辑
										</el-button>
										<el-button
											size="mini"
											type="danger"
											class="button_58px"
											v-if="!monthForm.id"
											:disabled="loading"
											@click="del(scope.row, scope.$index)"
											>删除
										</el-button>
										<el-button
											size="mini"
											type="primary"
											class="button_58px"
											:disabled="loading"
											v-if="monthForm.id && monthForm.id == scope.row.id"
											@click="add"
											>保存
										</el-button>
										<el-button
											size="mini"
											class="button_58px"
											:disabled="loading"
											v-if="monthForm.id && monthForm.id == scope.row.id"
											@click="editCancel"
											>取消
										</el-button>
									</div>
								</template>
							</el-table-column>
						</el-table>
					</div>
					<div class="add" v-if="qu_project_power.power.edit">
						<el-button
							type="text"
							icon="el-icon-plus"
							@click="add"
							:disabled="loading"
							>保存并添加新项
						</el-button>
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
import api from "@/api/methods/index";
import computedData from "@/mixins/computedData.js";
import util from "@/utils/public.js";

export default {
	mixins: [computedData],
	data() {
		return {
			month: "",
			area: "",
			tableData: [],
			monthForm: {
				shi: "",
				xiang: "",
				plan_num: undefined,
				month_start_num: undefined,
				start_num: undefined,
				month_finish_num: undefined,
				finish_num: undefined,
				project_speed_rate: undefined,
				plan_money: undefined,
				daowei_money: undefined,
				month_money: undefined,
				money: undefined,
				edit: true,
			},
			areaList: [
				{ value: 1, label: "州本级" },
				{ value: 2, label: "玛沁县" },
				{ value: 5, label: "甘德县" },
				{ value: 6, label: "达日县" },
				{ value: 4, label: "班玛县" },
				{ value: 3, label: "久治县" },
				{ value: 7, label: "玛多县" },
			],
			loading: false,

			// 列表
			show_list: true,
			list_year: "",
			list_area: 1,
			list_data: [],
		};
	},
	computed: {
		qu_project_power() {
			return this.$store.state.user.current_user.qu_project_power;
		},
	},
	created() {
		if (this.qu_project_power.area > 0) {
			this.area = this.qu_project_power.area;
			this.list_area = this.qu_project_power.area;
		} else {
			this.area = 1;
			this.list_area = 1
		}
		let year = new Date().getFullYear();
		let month = new Date().getMonth() + 1;
		if (month < 10) month = "0" + month;
		this.month = year + "-" + month;
		this.initMonthForm();
		this.getList();

		this.list_year = new Date().getFullYear() + "";
		this.getAllList();
	},
	methods: {
		initMonthForm() {
			this.monthForm = {
				shi: "",
				xiang: "",
				plan_num: undefined,
				month_start_num: undefined,
				start_num: undefined,
				month_finish_num: undefined,
				finish_num: undefined,
				project_speed_rate: undefined,
				plan_money: undefined,
				daowei_money: undefined,
				month_money: undefined,
				money: undefined,
				edit: true,
			};
			this.monthForm.shi = "果洛州";
			this.monthForm.xiang = this.initArea(this.area);
		},
		add() {
			if (!this.monthForm.shi) {
				this.$message.error("请填写州名");
				return;
			}
			if (!this.monthForm.xiang) {
				this.$message.error("请填写县名");
				return;
			}
			let param = {
				add_time: this.month,
				area: this.area,
				shi: this.monthForm.shi,
				xiang: this.monthForm.xiang,
			};
			if (this.monthForm.plan_num) {
				param.plan_num = this.monthForm.plan_num;
			}
			if (this.monthForm.month_start_num) {
				param.month_start_num = this.monthForm.month_start_num;
			}
			if (this.monthForm.start_num) {
				param.start_num = this.monthForm.start_num;
			}
			if (this.monthForm.month_finish_num) {
				param.month_finish_num = this.monthForm.month_finish_num;
			}
			if (this.monthForm.finish_num) {
				param.finish_num = this.monthForm.finish_num;
			}
			if (this.monthForm.project_speed_rate) {
				param.project_speed_rate = this.monthForm.project_speed_rate;
			}
			if (this.monthForm.plan_money) {
				param.plan_money = Number(this.monthForm.plan_money) * 10000;
			}
			if (this.monthForm.daowei_money) {
				param.daowei_money = Number(this.monthForm.daowei_money) * 10000;
			}
			if (this.monthForm.month_money) {
				param.month_money = Number(this.monthForm.month_money) * 10000;
			}
			if (this.monthForm.money) {
				param.money = Number(this.monthForm.money) * 10000;
			}
			if (this.monthForm.id) {
				param.id = this.monthForm.id;
			}
			api.report.offplanformAdd(param).then((res) => {
				if (res.data.state == 1) {
					this.$message({
						message: `记录${param.id ? "修改" : "添加"}成功`,
						type: "success",
					});
					this.initMonthForm();
					this.getList(false);
				}
			});
		},
		getList(load = true) {
			this.loading = true;
			if (load != false) {
				this.tableData = [];
			}
			let param = {
				add_time: this.month,
				area: this.area,
				page: 1,
				pagesize: 9999,
			};
			api.report.offplanformLists(param).then((res) => {
				if (res.data) {
					this.tableData = res.data.lists || [];
					if (this.tableData.length > 0) {
						this.tableData.forEach((item) => {
							item.edit = false;
							this.initRow(item);
						});
						this.tableData = [...this.tableData, this.monthForm];
					} else {
						this.tableData = [this.monthForm];
					}
				}
				this.loading = false;
			});
		},
		initRow(item) {
			if (
				item.plan_num === "" ||
				item.plan_num === null ||
				item.plan_num === undefined
			) {
				item.plan_num = undefined;
			}
			if (
				item.month_start_num === "" ||
				item.month_start_num === null ||
				item.month_start_num === undefined
			) {
				item.month_start_num = undefined;
			}
			if (
				item.start_num === "" ||
				item.start_num === null ||
				item.start_num === undefined
			) {
				item.start_num = undefined;
			}
			if (
				item.month_finish_num === "" ||
				item.month_finish_num === null ||
				item.month_finish_num === undefined
			) {
				item.month_finish_num = undefined;
			}
			if (
				item.finish_num === "" ||
				item.finish_num === null ||
				item.finish_num === undefined
			) {
				item.finish_num = undefined;
			}
			if (
				item.project_speed_rate === "" ||
				item.project_speed_rate === null ||
				item.project_speed_rate === undefined
			) {
				item.project_speed_rate = undefined;
			}
			if (
				item.plan_money === "" ||
				item.plan_money === null ||
				item.plan_money === undefined
			) {
				item.plan_money = undefined;
			} else {
				item.plan_money = Number(item.plan_money) / 10000;
			}
			if (
				item.daowei_money === "" ||
				item.daowei_money === null ||
				item.daowei_money === undefined
			) {
				item.daowei_money = undefined;
			} else {
				item.daowei_money = Number(item.daowei_money) / 10000;
			}
			if (
				item.month_money === "" ||
				item.month_money === null ||
				item.month_money === undefined
			) {
				item.month_money = undefined;
			} else {
				item.month_money = Number(item.month_money) / 10000;
			}
			if (item.money === "" || item.money === null || item.money === undefined) {
				item.money = undefined;
			} else {
				item.money = Number(item.money) / 10000;
			}
		},
		newForm() {
			this.tableData.push(this.monthForm);
		},
		exportForm() {
			let param = {
				add_time: this.month,
				area: this.area,
			};
			let _url = window.location.href;
			let __url = _url.split("#")[0];
			let url =
				__url +
				"index.php/cqh/Offplanform/leadingOut/?token=" +
				this.$store.state.user.token +
				"&add_time=" +
				param.add_time +
				"&area=" +
				param.area;
			api.report.offplanformOut(param).then((res) => {
				dd.biz.util.openLink({
					url, //要打开链接的地址
					onSuccess: function(result) {
						console.log("打开导出地址成功");
					},
					onFail: function(err) {
						console.log("打开导出地址失败", err);
					},
				});
			});
		},
		del(row, index) {
			const h = this.$createElement;
			this.$msgbox({
				title: "提示",
				message: h("p", null, "确定要删除该条报表记录吗?"),
				showCancelButton: true,
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				beforeClose: (action, instance, done) => {
					if (action === "confirm") {
						api.report.offplanformDelete({ id: row.id }).then((res) => {
							if (res.data.state == 1) {
								this.$message({
									message: "删除成功",
									type: "success",
								});
								this.tableData.splice(index, 1);
							}
						});
						done();
					} else {
						done();
					}
				},
			}).catch(() => {});
		},
		editRow(row) {
			this.initMonthForm();
			this.tableData = this.tableData.filter((a) => a.id);
			row.edit = true;
			this.monthForm = JSON.parse(JSON.stringify(row));
		},
		//取消编辑
		editCancel() {
			this.initMonthForm();
			this.tableData.forEach((item) => {
				item.edit = false;
			});
			this.tableData = [...this.tableData, this.monthForm];
		},

		getAllList() {
			api.report
				.allList({
					year: this.list_year,
					area: this.list_area || 0,
				})
				.then((res) => {
					if (res.data.state == 1) {
						const data = (res.data.lists || []).map((it) => ({
							...it,
							month: util.dateFormat(it.add_time, "M"),
						}));
						let result = [];
						for (let i = 0; i < 12; i++) {
							const item = data.find((it) => it.month == i + 1);
							if (item) {
								result.push(item);
							} else {
								result.push({
									month: i + 1,
									xiang: this.areaList.find((it) => it.value == this.list_area).label,
								});
							}
						}
						this.list_data = result;
					}
				});
		},
		async editForm(row) {
			const date = util.dateFormat(`${this.list_year}-${row.month}`, "YYYY-MM");
			const area = this.list_area;
			this.month = date;
			this.area = area;
			this.initMonthForm();
			await this.getList();
			this.show_list = false;
		},
	},
};
</script>

<style scoped>
.report_table {
	.label {
		line-height: 60px;
		text-align: center;
		font-size: 24px;
		width: 100%;
		display: inline-block;
	}

	.button {
		color: #409eff;
		cursor: pointer;
		font-weight: 500;
	}
}

.back {
	display: flex;
	align-items: center;
	color: #409eff;
	margin-right: 10px;
	cursor: pointer;
}
</style>
